/**
 *
 * Constants
 *
 * */

export const PHOTO_GALLERY = 'photoGallery';
export const DETAILS = 'details';
export const BIO = 'Bio';
export const PAYMENT = 'payment';
export const REVIEW = 'review';
export const SHARE = 'share';

export const MAP_BOX = 'find-a-rispot';

export const SIGNUP_PAGE_RE_CAPTCHA = 'signup_page';
export const CREATE_COMMENT_RECAPTCHA = 'create_comment';

// SEO
export const SEO_TITLE = 'Ripspot';
// export const prod_HNrxoK2d3vOpTw

export const MAX_ZOOM = 'Max Zoom';

//
// fixed prices
//
export const SUB_PRICE = 10;
export const SINGLE_CHARGE_PRICE = 29;

export default {
  PHOTO_GALLERY,
  DETAILS,
  BIO,
  PAYMENT,
  REVIEW,
  SHARE,
  MAP_BOX,
  MAX_ZOOM,
  SEO_TITLE,
  SIGNUP_PAGE_RE_CAPTCHA,
  //
  // PRICES!!!
  //
  SUB_PRICE,
  SINGLE_CHARGE_PRICE,
};
